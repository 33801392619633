<template>
  <div>
    <p>Sign Up works</p>
  </div>
</template>

<script>
export default {
  name: "SignUp"
}
</script>
